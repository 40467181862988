@use '@angular/material' as mat;

.mat-ink-bar {
  height: 4px !important;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$candy-app-primary: mat.define-palette(mat.$red-palette, 700);
$candy-app-accent: mat.define-palette(mat.$pink-palette, 200, 100, 400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($candy-app-theme);
@include mat.datepicker-theme($candy-app-theme);
