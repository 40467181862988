.rc-tag {
  border: 2px solid $grey-lighter;
  border-radius: 3px;
  color: $grey;
  display: inline-block;
  font-weight: 500;
  margin: 0 8px 0 0;
  overflow: hidden;
  padding: 8px 10px;
  position: relative;
  transition: border 0.2s;

  &__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding-left: 12px;
  }
}
