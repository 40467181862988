/**
 * Main
 *
 * This file import all our stylesheet.
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Utils
   ========================================================================== */
@import 'utils/all';

/* Base
   ========================================================================== */
@import 'base/reset';
@import 'base/base';

/* Layout
   ========================================================================== */
@import 'layout/overlay';
@import 'layout/header';
@import 'layout/main';
@import 'layout/position';
@import 'layout/product';

/* Module
   ========================================================================== */
@import 'module/icons';
@import 'module/title';
@import 'module/button';
@import 'module/input';
@import 'module/select';
@import 'module/table';
@import 'module/card';
@import 'module/chart';
@import 'module/dialog';
@import 'module/tags';
@import 'module/action';
@import 'module/product-cart';

@include font-face('DINProRegular', '../../assets/fonts/DINPro-Regular');
@include font-face('DINProMedium', '../../assets/fonts/DINPro-Medium');
@include font-face('DINProBold', '../../assets/fonts/DINPro-Bold');
