@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: '?',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url("#{quote($path + '.' + $extmod)}") format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin center($width, $height: 0) {
  position: absolute;

  @if $height != 0 {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$height} / 2));
  } @else {
    left: calc(50% - (#{$width} / 2));
    top: calc(50% - (#{$width} / 2));
  }
}

// Responsiveness

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin large-mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin until-widescreen {
  @media screen and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin block($spacing: $block-spacing) {
  &:not(:last-child) {
    margin-bottom: $spacing;
  }
}

@mixin button-reset {
  background-color: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
}

@mixin button-with-icon {
  @include button-reset;

  align-items: center;
  display: flex;

  app-icon {
    fill: currentColor;
    height: 16px;
    width: 16px;
  }

  span {
    padding-left: $size-8;
  }
}

@mixin dropdown-button-chevron {
  @include button-reset;

  color: $primary;
  display: block;
  display: flex;
  font-family: $din-medium;
  font-size: $font-size-body-smaller;

  .icon {
    fill: $grey-light;
    height: auto;
    margin-left: $size-8;
    width: 16px;
  }

  .icon.rotate {
    transform: rotate(180deg);
  }
}

@mixin create-shadow($size: $size-6, $color: rgba(0, 0, 0, 0.25)) {
  box-shadow: 0 0 $size $size $color;
}

@mixin ul-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin rc-form {
  padding: 16px 33px;

  &-attribute {
    padding: 16px 0;

    &__key {
      color: $grey;
      font-family: $din-regular;
    }

    &__value {
      color: $grey;
      font-family: $din-medium;

      a {
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }

      .disabled:hover {
        cursor: not-allowed;
      }
    }

    &__value-input,
    &__value-select {
      margin: 10px 0 0;
      width: 100%;
    }
  }

  &__hyperlink {
    cursor: pointer;
  }
}

@mixin tag-style {
  border: $border-normal $app-background;
  border-radius: $radius;
  display: inline-block;
  font-weight: $weight-bold;
  padding: $size-8 $size-7;
}

@mixin uppercase-title {
  font-size: $font-size-body-smaller;
  font-weight: $weight-bold;
  margin-bottom: $size-5;
  text-transform: uppercase;
}

@mixin input-radio {
  appearance: none;
  border: $border-small $grey-lighter;
  border-radius: (50%);
  height: rem(24px);
  position: relative;
  width: rem(24px);

  &::before {
    background-color: $primary;
    border-color: $primary;
    border-radius: 50%;
    content: '';
    font-size: 1.2em;
    height: 12px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    width: 12px;
  }

  &:checked::before {
    visibility: visible;
  }

  &:checked {
    border-color: $primary;
  }
}

@mixin edit-footer {
  background-color: $white;
  border-top: 1px solid $grey-lighter;
  bottom: 0;
  height: 110px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 3;

  .wrapper {
    align-items: baseline;
    display: flex;
  }

  .footer-buttons {
    align-items: center;
    display: flex;
    height: 100%;
    @include from($tablet) {
      justify-content: center;
    }
  }

  .reset {
    position: absolute;
    right: 0;
    right: 5%;
    top: 35%;
  }

  .rc-button {
    display: block;
  }

  .icon {
    display: inline-block;
    margin-right: $size-8;
    vertical-align: middle;
  }
}

@mixin tooltip {
  display: none;
  @include from($desktop) {
    background-color: #333;
    display: block;
    font-size: $font-size-taxonomie;
    line-height: 18px;
    max-width: 240px !important;
    overflow: visible !important;
    padding: $size-8;
    position: relative;
    text-align: center;

    &::before {
      border-bottom: $size-9 solid #333;
      border-left: $size-9 solid transparent;
      border-right: $size-9 solid transparent;
      bottom: 100%;
      content: '';
      display: block;
      height: 0;
      left: calc(50% - #{$size-9});
      position: absolute;
      width: 0;
    }
  }
}

@mixin checked-rounded {
  background-color: $success;
  border: $border-small $success;
  border-radius: 50%;
  display: block;
  height: 24px;
  position: relative;
  width: 24px;

  &::after {
    border: solid $white;
    border-width: 0 2px 2px 0;
    content: '';
    height: 14px;
    left: 7px;
    position: absolute;
    top: 2px;
    transform: rotate(45deg);
    width: 7px;
  }
}

@mixin badge {
  background-color: $primary;
  border-radius: $size-2;
  color: $white;
  font-size: $font-size-taxonomie--smaller;
  padding: 2px 8px;
  text-align: center;
}

@mixin datepicker {
  position: relative;

  input {
    border: 0;
    border-color: 2px solid $grey-lighter;
    border-style: solid;
    border-width: 0 0 2px;
    color: $grey;
    font-family: $din-regular, sans-serif;
    line-height: 48px;
    margin-top: 0;
    transition: border-color ease 0.15s, color ease 0.15s;
    width: 100%;

    &:not(:placeholder-shown) {
      border-color: $grey;
    }
  }

  .error-state input {
    border-color: $error;
  }

  .datepicker {
    align-items: center;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
  }

  .datepicker-error {
    color: $error;
    font-size: rem(12px);
    left: 0;
    margin-top: rem(10px);
    position: absolute;
    white-space: normal;
  }

  .datepicker-icon {
    cursor: pointer;
    fill: $grey-light;
    position: absolute;
    right: 0;
  }

  .datepicker-icon:hover {
    fill: $primary;
  }

  .is-disabled {
    cursor: not-allowed;
    opacity: 0.3;

    .datepicker-icon {
      cursor: default;

      &:hover {
        fill: $grey-light;
      }
    }
  }

  .datepicker-info {
    padding: $size-8 0;
  }
}

@mixin expand-icon {
  fill: $grey-light;
  flex-shrink: 0;
  margin-left: $size-8;
  transition: all 0.25s ease;
  width: $icon-width;

  &.reversed {
    transform: rotate(180deg);
  }
}
