/**
 * Card
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */
@import '~styles/utils/site-variables';

.rc-card-list {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.rc-card {
  background-color: $white;
  border: 1px solid $grey-lighter;
  position: relative;

  &--selected {
    border: 1px solid $primary-dark;
  }

  &__link {
    display: block;
    height: 100%;
    width: 100%;
  }

  &__item {
    font-family: $din-medium;
    font-size: 14px;
    left: 0;
    padding: 0 10px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__title {
    color: $grey-light;
    display: block;
    margin-top: 20px;
  }
}

/* Responsive design
========================================================================== */

/**
 * Devices between (960px and up)
 */
@media only screen and (min-width: 960px) {
  .rc-card__title {
    margin-top: 40px;
  }
}
