/**
 * Product-cart
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

.product-cart {
  background-color: $white;
  border-top: 1px solid $grey-lighter;
  display: block;
  min-height: 50px;
  opacity: 0;
  transform: translateY(100%);
  transition: 200ms cubic-bezier(0.5, 0.5, 0.76, 0.76);
  width: 100%;
  z-index: 2;

  &__total {
    display: inline-block;
    position: relative;
  }

  &--editable {
    height: inherit;
  }

  &__header {
    position: relative;
  }

  &__button {
    display: block;
    margin: 5px auto;

    &.is-active {
      margin-top: 10px;
    }
  }

  &__content {
    display: none;
    margin-top: 30px;
  }

  &__content--editable {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }

  &__item {
    border: 1px solid $grey-lighter;
    margin: 0 15px 20px;
    padding: 15px 20px;
    position: relative;
  }

  &__item-thumb {
    height: 50px;
    left: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__item-name {
    color: $grey-darker;
  }

  &__item-remove {
    height: 29px;
    min-width: 40px;
    padding: 0;
    position: absolute;
    right: 10px;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
  }

  &__item--dashed {
    border-style: dashed;
    height: 80px;
    text-align: center;

    .rc-paragraph--meta {
      line-height: 45px;
    }
  }

  &__description {
    margin-left: 50px;
    max-width: 140px;
  }

  &__footer {
    bottom: 5%;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    transform: translateY(200%);

    &.is-expand {
      transform: translateY(0);
    }
  }

  &.is-open {
    opacity: 1;
    transform: translateY(0);
  }

  &.is-expand {
    height: 400px;

    .product-cart__content {
      display: block;
    }
  }
}

@include from($desktop) {
  .product-cart {
    display: block;
    min-height: 110px;

    &__header {
      display: none;
    }

    &__content {
      display: block;
      margin: 15px;
      position: relative;
    }

    &__item {
      display: inline-block;
      height: 80px;
      margin: 0 44px 0 0;
      vertical-align: middle;
      white-space: nowrap;
      width: $product-card-width;

      &:last-child {
        margin: 0;
      }

      &--list-vertical {
        display: block;
        margin: 0 auto 15px;

        &:last-child {
          margin: 0 auto;
        }
      }
    }

    &__item--dashed {
      height: inherit;
    }

    &__description {
      max-width: 200px;
    }

    &__item-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__footer {
      left: inherit;
      padding-top: 5px;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    &.is-expand {
      height: 110px;
      overflow-y: inherit;
    }

    .rc-button-group > .rc-button:nth-child(1),
    .rc-button-group > .rc-button__separator {
      display: none;
    }
  }
}

/**
 * Extra large devices (large laptops and desktops, 1200px and up)
 */
@media only screen and (min-width: 1200px) {
  .product-cart__content {
    margin: 0 auto;
    max-width: 1055px;
    padding: 15px;

    &--list-vertical {
      margin-left: auto;
      margin-right: auto;
      max-width: 360px;
    }

    &--editable {
      padding: 0;
    }
  }
}

.product-cart.is-withMobileFilters {
  display: block;
  @include from($tablet) {
    display: none;
  }

  .search-section {
    display: flex;
    justify-content: center;
    padding: $size-6 0;
  }

  .header-selection {
    color: $primary;
    font-family: $din-regular;
    font-size: $font-size-body-smaller;
  }

  .header-panel {
    color: $primary;
    font-family: $din-medium;
    font-size: $font-size-body-smaller;
    margin-right: $size-8;
  }

  .product-selection {
    border-bottom: $border-small $grey-lighter;
    justify-content: space-between;
    padding: rem(14px) rem(18px);
    width: 100%;

    .icon {
      fill: $grey-light;
      height: rem(8.4px);
      width: rem(16px);
    }
  }

  .sub-selection {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px;
  }

  .badge {
    align-items: center;
    background-color: $primary;
    border-radius: 99rem;
    color: $white;
    display: flex;
    font-size: $font-size-taxonomie--smaller;
    height: 16px;
    justify-content: center;
    margin-left: 4px;
    width: 16px;
  }

  .product-cart__header {
    margin: 0 auto;
  }

  .align-row {
    align-items: baseline;
    display: flex;
  }
}

.product-cart:not(.is-withSelectedProduct) {
  display: block;
  @include from($tablet) {
    display: none;
  }
}

.product-cart.is-withSelectedProduct {
  display: block;
}

.footer--withMobileFilters {
  display: none;
}

.is-withMobileFilters .product-cart__footer .footer--withoutMobileFilters {
  display: none;
  @include from($tablet) {
    display: block;
  }
}

.is-withMobileFilters.is-withSelectedProduct .footer--withMobileFilters {
  display: block;
  @include from($tablet) {
    display: none;
  }
}

.product-footer:not(.is-withSelectedProduct) .product-cart__footer {
  display: none;
}

.product-cart.is-withMobileFilters.is-withSelectedProduct {
  @include from($tablet) {
    display: block;
  }
}
