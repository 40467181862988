/**
 * Icons
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Icons
   ========================================================================== */

/**
 * RC Icon
 *
 * Import all icons from one file `rc_icon.svg`, no explicit width
 * & position by default. Extend with `.rc-icon--n` classes.
 *
 * Example HTML:
 *
 * <span class="rc-icon rc-icon--search"></span>
 *
 */

@import '~styles/utils/all';

.rc-icon {
  background-image: url('../../assets/icons/rc_icons.svg?12');
  background-repeat: no-repeat;
  display: inline-block;
  margin: 0 auto;

  &--disabled {
    opacity: 0.3;
  }

  &-pdf {
    background-image: url('../../assets/icons/royal-canin.sprite--iconography--xs.svg');
    background-position: -198px -135px;
    height: 17px;
    margin: inherit;
    width: 20px;

    &.active {
      background-image: url('../../assets/icons/royal-canin.sprite--brand1--xs.svg');
    }
  }
}

.rc-icon-reversed {
  transform: rotate(180deg);
}

.rc-icon--action-submit {
  background-position: -252px -98px;
  height: 15px;
  width: 20px;
}

.rc-icon--minidirectory {
  background-position: -197px -873px;
}

.rc-icon--product {
  background-position: -14px -216px;

  &.active,
  &::after {
    background-position: -40px -216px;
  }
}

.rc-icon--search {
  background-position: -66px -216px;

  &.active,
  &::after {
    background-position: -66px -216px;
  }

  &.error {
    background-position: -229px -779px;
  }
}

.rc-icon--search-big {
  background-position: -82px -14px;
  height: 24px;
  margin: -6px auto;
  width: 24px;

  &.active,
  &.error {
    background-position: -116px -14px;
  }
}

.rc-icon--calendar {
  background-position: -83px -905px;

  &.active,
  &::after {
    background-position: -109px -905px;
  }

  &.error {
    background-position: -255px -779px;
  }
}

.rc-icon--notif {
  background-position: -118px -216px;

  &.active,
  &::after {
    background-position: -141px -216px;
  }
}

.rc-icon--profile {
  background-position: -164px -216px;

  &.active,
  &::after {
    background-position: -190px -216px;
  }
}

.rc-icon--profile-active {
  background-position: -190px -216px;
}

.rc-icon--basket {
  background-position: -245px -610px;
  height: 16px;
  width: 16px;
}

.rc-icon--add-people {
  background-position: -122px -458px;
}

.rc-icon--weight-loss {
  background-position: -42px -458px;
}

.rc-icon--smart-reco {
  background-position: -68px -458px;
}

.rc-icon--daily-allowance {
  background-position: -16px -458px;
}

.rc-icon--multifunctions {
  background-position: -96px -458px;
}

.rc-icon--pet_profile,
.rc-icon--body-score,
.rc-icon--weight,
.rc-icon--weight-tracking,
.rc-icon--visit,
.rc-icon--agenda,
.rc-icon--consultation,
.rc-icon--inventory,
.rc-icon--reset,
.rc-icon--academy,
.rc-icon--pet-record,
.rc-icon--multifunction,
.rc-icon--vetportal,
.rc-icon--pathology,
.rc-icon--sensitivity,
.rc-icon--pathologies,
.rc-icon--community {
  height: 32px;
  width: 32px;
}

.rc-icon--pet_profile {
  background-position: -245px -663px;
}

.rc-icon--body-score {
  background-position: -132px -865px;
}

.rc-icon--weight {
  background-position: -77px -634px;
}

.rc-icon--weight-tracking {
  background-position: -7px -865px;
}

.rc-icon--visit {
  background-position: -7px -865px;
}

.rc-icon--pathologies {
  background-position: -14px -941px;
}

.rc-icon--pathology {
  background-position: -147px -740px;

  &.active,
  &::after {
    background-position: -173px -740px;
  }
}

.rc-icon--sensitivity {
  background-position: -94px -742px;

  &.active,
  &::after {
    background-position: -190px -216px;
  }
}

.rc-icon--agenda {
  background-position: -182px -48px;

  &.active,
  &::after {
    background-position: -224px -48px;
  }
}

.rc-icon--consultation {
  background-position: -14px -174px;
  width: 24px;

  &.active,
  &::after {
    background-position: -48px -174px;
  }
}

.rc-icon--cross {
  background-position: -15px -753px;
}

.rc-icon--info {
  background-position: -41px -753px;
}

.rc-icon--reset {
  background-position: -196px -674px;
  height: 14px;
  width: 16px;

  &.active,
  &::after {
    background-position: -222px -674px;
  }
}

.rc-icon--inventory {
  background-position: -10px -90px;

  &.active,
  &::after {
    background-position: -52px -90px;
  }
}

.rc-icon--academy {
  background-position: -10px -48px;

  &.active,
  &::after {
    background-position: -52px -48px;
  }
}

.rc-icon--pet-record {
  background-position: -10px -132px;

  &.active,
  &::after {
    background-position: -53px -132px;
  }
}

.rc-icon--community {
  background-position: -90px -132px;

  &.active,
  &::after {
    background-position: -132px -132px;
  }
}

.rc-icon--vet-portal,
.rc-icon--multi,
.rc-icon--rationing {
  height: 100%;
  width: auto;
}

.rc-icon--vet-portal {
  background-position: 0 -788px;
}

.rc-icon--multi {
  background-position: -80px -788px;
}

.rc-icon--rationing {
  background-position: -157px -788px;
}

.rc-icon--check {
  background-position: -244px -242px;
  height: 16px;
  width: 16px;

  &.active {
    background-position: -218px -242px;
  }
}

.rc-icon--advice {
  background-position: -94px -48px;
  height: 32px;
  width: 32px;
}

.icon-food {
  fill: $grey;
  margin-right: $size-8;
}

.rc-icon--food {
  background-position: -240px -18px;
  height: 16px;
  width: 14px;

  &.active,
  &::after {
    background-position: -260px -18px;
  }
}

.rc-icon--support {
  background-position: -14px -242px;
  height: 16px;
  width: 18px;

  &.active,
  &::after {
    background-position: -38px -243px;
  }
}

.rc-icon--settings,
.rc-icon--settings-active {
  height: 24px;
  width: 24px;
}

.rc-icon--settings {
  background-position: -127px -178px;
}

.rc-icon--settings:hover {
  background-position: -161px -178px;
}

.rc-icon--settings-active {
  background-position: -161px -178px;
}

.rc-icon--logout {
  background-position: -195px -178px;
}

.rc-icon--logout:hover {
  background-position: -227px -178px;
}

.rc-icon--close {
  background-position: -94px -97px;
  height: 18px;
  width: 18px;
}

.rc-icon--trash {
  background-position: -207px -714px;
  height: 16px;
  width: 16px;

  &.active,
  &:hover {
    background-position: -233px -714px;
  }
}

.rc-icon--shop {
  background-position: -15px -605px;
  height: 24px;
  width: 24px;
}

.rc-icon--information {
  background-position: -83px -639px;
  height: 24px;
  width: 24px;
}

.rc-icon--grid {
  background-position: -143px -609px;
  height: 16px;
  width: 16px;

  &.is-active,
  &::after {
    background-position: -168px -609px;
  }
}

.rc-icon--list {
  background-position: -193px -609px;
  height: 16px;
  width: 16px;

  &.is-active,
  &::after,
  &:hover {
    background-position: -219px -609px;
  }
}

.rc-icon--filter {
  background-position: -117px -673px;
  height: 21px;
  width: 24px;

  &.active,
  &::after {
    background-position: -83px -673px;
  }
}

.icon-edit {
  fill: $grey;
  margin-right: $size-8;
}

// Order status
.rc-icon--pending {
  background-position: -16px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--pending-white {
  background-position: -16px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--awaiting-approval {
  background-position: -67px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--awaiting-approval-white {
  background-position: -67px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--validated {
  background-position: -95px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--validated-white {
  background-position: -93px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--processed {
  background-position: -119px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--processed-white {
  background-position: -119px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--personalized {
  background-position: -145px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--personalized-white {
  background-position: -145px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--ready-for-wms {
  background-position: -171px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--ready-for-wms-white {
  background-position: -171px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--shipped {
  background-position: -197px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--shipped-white {
  background-position: -198px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--awaiting-b2b-loyalty {
  background-position: -225px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--awaiting-b2b-loyalty-white {
  background-position: -225px -975px;
  height: 16px;
  width: 16px;
}

.rc-icon--ready-for-vet-invoiced {
  background-position: -251px -810px;
  height: 16px;
  width: 16px;
}

.rc-icon--ready-for-vet-invoiced-white {
  background-position: -251px -976px;
  height: 16px;
  width: 16px;
}

.rc-icon--invoiced {
  background-position: -15px -835px;
  height: 16px;
  width: 16px;
}

.rc-icon--closed {
  background-position: -15px -835px;
  height: 16px;
  width: 16px;
}

.rc-icon--closed-white {
  background-position: -15px -1000px;
  height: 16px;
  width: 16px;
}

.rc-icon--refused {
  background-position: -41px -835px;
  height: 16px;
  width: 16px;
}

.rc-icon--canceled {
  background-position: -67px -835px;
  height: 16px;
  width: 16px;
}

.rc-icon--active {
  background-position: -197px -835px;
  height: 16px;
  width: 16px;
}

.rc-icon--renew {
  background-position: -93px -835px;
  height: 16px;
  width: 16px;
}

.rc-icon--tool-calculator,
.rc-icon--tool-weight,
.rc-icon--tool-directory {
  height: 62px;
  width: 62px;
}

.rc-icon--tool-calculator {
  background-position: -117px -268px;
}

.rc-icon--tool-weight {
  background-position: -219px -268px;
}

.rc-icon--tool-directory {
  background-position: -15px -268px;
}

.rc-icon--link {
  height: 20px;
  width: 20px;

  &-enabled {
    background-position: -114px -673px;
  }

  &-disabled {
    background-position: -84px -673px;
  }
}

.rc-icon--calculator,
.rc-icon--animal {
  height: 24px;
  width: 26px;
}

.rc-icon--calculator {
  background-position: -208px -133px;
}

.rc-icon--animal {
  background-position: -251px -671px;
  height: 17px;
}

.rc-icon--medical-record {
  background-position: -139px -710px;
  height: 24px;
  width: 24px;
}

.rc-icon--alert {
  background-position: -62px -242px;
  height: 16px;
  width: 16px;

  &-active {
    background-position: -88px -242px;
  }
}

.rc-icon--email {
  background-position: -216px -217px;
  height: 13px;
  width: 16px;
}

.rc-icon--print {
  background-position: -144px -673px;
  height: 16px;
  width: 16px;
}

.rc-icon--dots-vertical {
  background-position: -193px -904px;
  height: 25px;
  width: 10px;

  &-active {
    background-position: -193px -904px;
  }
}

.rc-icon--cat {
  background-position: -106px -349px;
  height: 38px;
  width: 40px;

  &.is-active {
    background-position: -158px -349px;
  }
}

.rc-icon--upload {
  background-position: -15px -402px;
  height: 40px;
  width: 52px;

  &.is-failed {
    background-position: -15px -703px;
  }
}

.rc-icon--dog {
  background-position: -12px -349px;
  height: 38px;
  width: 40px;

  &.is-active {
    background-position: -58px -349px;
  }
}

.rc-icon--male {
  background-position: -205px -753px;
  height: 16px;
  width: 16px;

  &.is-active {
    background-position: -231px -753px;
  }
}

.rc-icon--female {
  background-position: -15px -779px;
  height: 16px;
  width: 16px;

  &.is-active {
    background-position: -41px -779px;
  }
}

.rc-icon--indoor {
  background-position: -67px -779px;
  height: 16px;
  width: 16px;

  &.is-active {
    background-position: -93px -779px;
  }
}

.rc-icon--outdoor {
  background-position: -173px -779px;
  height: 16px;
  width: 16px;

  &.is-active {
    background-position: -203px -779px;
  }
}

.rc-icon--both {
  background-position: -119px -779px;
  height: 16px;
  width: 16px;

  &.is-active {
    background-position: -145px -779px;
  }
}

.rc-icon--dots-horizontal {
  background-position: -15px -913px;
  height: 6px;
  width: 24px;
}

.rc-icon--chevron-white {
  &-right,
  &-left {
    height: 24px;
    vertical-align: middle;
    width: 16px;
  }

  &-right {
    background-position: -166px -940px;
  }

  &-left {
    background-position: -187px -940px;
  }
}

.rc-icon--arrow-red {
  background-position: -66px -756px;
  height: 10px;
  vertical-align: middle;
  width: 10px;
}

.rc-icon--arrow-right--white {
  background-position: -254px -100px;
  height: 12px;
  width: 16px;
}

.rc-icon--arrow {
  &-up {
    transform: rotate(180deg);
  }

  &-left {
    transform: rotate(90deg);
  }

  &-right {
    transform: rotate(-90deg);
  }

  &-down,
  &-up {
    background-position: -150px -100px;
    height: 14px;
    width: 20px;

    &.active {
      background-position: -174px -100px;
    }
  }

  &-left,
  &-right {
    background-position: -148px -100px;
    height: 14px;
    width: 20px;

    &.active {
      background-position: -174px -100px;
    }
  }

  &-down--white {
    background-position: -165px -944px;
    height: 16px;
    transform: rotate(90deg);
    width: 16px;
  }
}

.rc-icon--arrow2 {
  &-down--blue,
  &-down--green,
  &-up--blue,
  &-up--green,
  &-equal {
    height: 16px;
    margin: 0 2px;
    width: 13px;
  }
}

.rc-icon--arrow2-down {
  &--blue {
    background-position: -212px -905px;
    transform: translateY(2px);
  }

  &--green {
    background-position: -255px -905px;
    transform: translateY(2px);
  }
}

.rc-icon--arrow2-up {
  &--blue {
    background-position: -212px -905px;
    transform: rotate(180deg) translateY(-2px);
  }

  &--green {
    background-position: -255px -905px;
    transform: rotate(180deg) translateY(-2px);
  }
}

.rc-icon--arrow2-equal {
  background-position: -235px -905px;
}

.rc-icon--chevron {
  &-left,
  &-right {
    height: 25px;
    width: 10px;
  }

  &-left {
    background-position: -78px -938px;
  }

  &-right {
    background-position: -56px -938px;
  }
}

/**
 * Segmented control icons
 */

.rc-icon--segmented-weight {
  background-position: -80px -865px;
  height: 24px;
  width: 24px;
}

.rc-icon--pipette {
  background-position: -146px -264px;
  height: 28px;
  width: 27px;
}

.rc-icon--flask {
  background-position: -179px -268px;
  height: 28px;
  width: 27px;
}

.rc-icon--cup {
  background-position: -215px -264px;
  height: 28px;
  width: 27px;
}

/* Icon modifiers
   ========================================================================== */

.rc-icon--product,
.rc-icon--search,
.rc-icon--calendar,
.rc-icon--cross,
.rc-icon--info,
.rc-icon--notif,
.rc-icon--profile,
.rc-icon--profile-active,
.rc-icon--agenda,
.rc-icon--consultation,
.rc-icon--inventory,
.rc-icon--academy,
.rc-icon--minidirectory,
.rc-icon--community,
.rc-icon--weight-loss,
.rc-icon--smart-reco,
.rc-icon--daily-allowance,
.rc-icon--multifunctions,
.rc-icon--add-people,
.rc-icon--arrow-down {
  height: 16px;
  position: relative;
  width: 16px;

  &::after {
    background-image: url('../../assets/icons/rc_icons.svg?12');
    background-repeat: no-repeat;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 500ms;
  }

  &.active,
  &:hover::after {
    opacity: 1;
  }
}

.rc-icon--arrow-top:hover,
.rc-icon--arrow-left:hover,
.rc-icon--arrow-right:hover,
.rc-icon--arrow-down:hover {
  background-position: -174px -100px;
}

/*
   Responsive design
   ========================================================================== */

/**
 * Devices between (960px and up)
 */
@media only screen and (min-width: 960px) {
  .rc-icon--product {
    background-position: -14px -14px;

    &.active,
    &::after {
      background-position: -48px -14px;
    }
  }

  /* .rc-icon--search {
    background-position: -82px -14px;
    &.active, &::after { background-position: -116px -14px; }
  } */

  .rc-icon--notif {
    background-position: -182px -14px;

    &.active,
    &::after {
      background-position: -212px -14px;
    }
  }
}
