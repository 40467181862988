$theme-primary: #61adbc;
$theme-primary-dark: #1a61adbc;

/** Force external lib primary color **/
::ng-deep {
  .rc-autocomplete__list__item__checkmark--active::after {
    border-color: #1a61adbc;
  }

  .rc-select__toggle .red-icon {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTZweCIgaGVpZ2h0PSI5cHgiIHZpZXdCb3g9IjAgMCAxNiA5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+RmlsbC0xPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IlJTLUljb25zLVNwcml0ZS1TcHJpbnQtNTMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00OC4wMDAwMDAsIC0xODg3LjAwMDAwMCkiIGZpbGw9IiM3Njc2NzYiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxnIGlkPSJpY29uc19yb3lhbHN0YXJ0X1ZGSU5BTEUiPgogICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuMDAwMDAwLCAxODIzLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik01My4zMDMyNSw3Mi4yNDg1OTcxIEw0Ny4yOTMyNSw2NS44MjQ3OTc0IEM0Ni45MDIyNSw2NS40MDY4NzYzIDQ2LjkwMjI1LDY0LjczMTM2MTkgNDcuMjkzMjUsNjQuMzEzNDQwOCBDNDcuNjg0MjUsNjMuODk1NTE5NyA0OC4zMTYyNSw2My44OTU1MTk3IDQ4LjcwNzI1LDY0LjMxMzQ0MDggTDU0LjcxNzI1LDcwLjczNzI0MDYgQzU0LjkyMTI1LDcwLjk1NDIxNzUgNTUuMDc5MjUsNzAuOTU0MjE3NSA1NS4yODIyNSw3MC43MzcyNDA2IEw2MS4yOTMyNSw2NC4zMTM0NDA4IEM2MS42ODQyNSw2My44OTU1MTk3IDYyLjMxNjI1LDYzLjg5NTUxOTcgNjIuNzA3MjUsNjQuMzEzNDQwOCBDNjMuMDk4MjUsNjQuNzMxMzYxOSA2My4wOTgyNSw2NS40MDY4NzYzIDYyLjcwNzI1LDY1LjgyNDc5NzQgTDU2LjY5NzI1LDcyLjI0ODU5NzEgQzU2LjI0NDI1LDcyLjczMjc4NyA1NS42NDEyNSw3MyA1NS4wMDAyNSw3MyBDNTQuMzU5MjUsNzMgNTMuNzU2MjUsNzIuNzMyNzg3IDUzLjMwMzI1LDcyLjI0ODU5NzEgWiIgaWQ9IkZpbGwtMSI+PC9wYXRoPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=') !important;
  }
}
