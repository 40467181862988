@import '~styles/utils/site-variables';

/**
 * Base
 *
 * The role of base is to have all default settings configuration.
 * Must contains only default config, you should never override class
 * on this file.
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

html,
body {
  width: 100%;
}

body {
  background-color: $app-background;
  color: $grey;
  color-adjust: exact;
  font-family: $din-regular;
  font-size: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

*::-webkit-scrollbar {
  width: rem(16px);
}

*::-webkit-scrollbar-track {
  background: $app-background;
  border: $border-small $grey-lighter;
}

*::-webkit-scrollbar-thumb {
  background-clip: padding-box; /* hack to simulate padding */
  background-color: $grey-lighter;
  border: 4px solid rgb(0 0 0 / 0%); /* hack to simulate padding */
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #808285;
}

a {
  text-decoration: none;
}

.center {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.no-scroll {
  overflow: hidden;
}

.divider-table {
  background-color: $app-background;
  height: 1px;
  width: 100%;
}

.divider {
  background-color: $app-background;
  height: 1px;
  margin: 20px 0;
  width: 100%;
}

.divider-without-margin {
  background-color: $app-background;
  height: 1px;
  width: 100%;
}

.rc-reset-list {
  list-style: none;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
  padding-inline-start: 0;
}

/* Mars Footer
   ========================================================================== */

/**
 * Override mars footer
 */

.mars-footer-container {
  position: relative !important;
  top: 20px !important;
}

.mars-footer-legal {
  margin-bottom: 0 !important;
}

.mars-footer--withPanelOpened {
  @include from($desktop) {
    // we are force to set max-width here instead of inside the mars-footer-container
    // because mars footer has styles with !important set on width and max-width
    // in conclusion, an animation on transform can not work
    max-width: calc(100% - #{$summary-width--open});

    .mars-footer-container {
      transform: translateX($summary-height--open);
    }
  }
}

/* Print View
   ========================================================================== */

@media print {
  @page {
    margin: 0;
    size: portrait;
  }

  [id='cookie-consent'] {
    display: none;
  }

  .mars-footer-container {
    display: none;
  }
}
