@import 'theme';
@import 'functions';

/**
 * Site variables
 *
 * This allow us to reuse those variable inside any views / components
 * It's also avoid to duplicate code.
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */

/* Fonts
   ========================================================================== */

// Font sizes to use from desktop breakpoint
$font-size-body: rem(16px);
$font-size-body-large: rem(18px);
$font-size-intro-large: rem(22px);
$font-size-numeric: rem(22px);
$font-size-taxonomie: rem(14px);
$font-size-h1: rem(40px);
$font-size-h2: rem(30px);
$font-size-h3: rem(26px);
$font-size-h4: rem(20px);
$font-size-h5: rem(18px);
$font-size-h6: rem(16px);

// Font sizes to use until tablet breakpoint
$font-size-body-smaller: rem(14px);
$font-size-body-large-smaller: rem(16px);
$font-size-intro-large--smaller: rem(18px);
$font-size-numeric--smaller: rem(20px);
$font-size-taxonomie--smaller: rem(12px);
$font-size-h1--smaller: rem(30px);
$font-size-h2--smaller: rem(26px);
$font-size-h3--smaller: rem(22px);
$font-size-h4--smaller: rem(18px);
$font-size-h5--smaller: rem(16px);
$font-size-h6--smaller: rem(14px);

$din-regular: dinproregular, arial, sans-serif;
$din-medium: dinpromedium, arial, sans-serif;
$din-bold: dinprobold, arial, sans-serif;
$helvetica: helvetica, arial, sans-serif;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

/* Colors
   ========================================================================== */

$primary: $theme-primary;

$cooler-2: #6fa7ac;
$cooler-4: #7e89a9;

// To be used for hovers
$primary-dark: $theme-primary-dark;
$black: #000;
$white: #fff;

// To be used for app background and separators
$app-background: #f6f6f6;

// To be used darker text color
$grey-darker: #333;

// To be used for hyperlink
$grey-dark: #444;

// To be used for text color and darker borders
$grey: #666;

// To be used for lighter text color and icon colors
$grey-light: #767676;

// To be used for box shadow
$grey-shadow: #b3b3b3;

// To be used for borders
$grey-lighter: #d7d7d7;

$info: #5ca9ad;
$success: #008900;
$warning: #ee8b00;
$error: #c03344;

/* Spacing
   ========================================================================== */

$size-1: rem(48px);
$size-2: rem(40px);
$size-3: rem(32px);
$size-4: rem(24px);
$size-5: rem(20px);
$size-6: rem(16px);
$size-7: rem(12px);
$size-8: rem(8px);
$size-9: rem(5px);

$icon-width: rem(16px);

/* Responsiveness
   ========================================================================== */

$mobile: 320px;
$large-mobile: 414px;
$tablet: 768px;
$desktop: 1100px;
$widescreen: 1366px;

$container-s: rem(920px);
$container-m: rem(1160px);
$container-l: rem(1400px);
$block-spacing: rem(20px);
$form-field-spacing: rem(55px);

/* Miscellaneous
   ========================================================================== */

$easing: ease-in-out;
$speed: 250ms;

$radius-small: 2px;
$radius: 3px;
$radius-large: 6px;
$radius-rounded: 50%;

$border-small: 1px solid;
$border-medium: 2px solid;
$border-small-dashed: 1px dashed;
$border-normal: 2px solid;

$line-height: 1.4;

$header-height: rem(105px);
$header-height--desktop: rem(90px);
$sub-header-height: rem(40px);
$sub-header-height--desktop: rem(60px);

$page-wrapper-content-padding: $size-5;

$header-height-standalone: rem(90px);
$progress-height-standalone: rem(10px);

$product-catalog-main-header-height: rem(80px);

/* Product cards ========================================================================== */

$product-card-width: rem(320px);
$product-card-margin-btw: rem(40px);

$product-edit-card-height--mobile: rem(480px);
$product-edit-card-height--desktop: rem(510px);
$product-edit-card-header-height: rem(175px);

/* Summary Panel ========================================================================== */

$aside-navigation-width--open: calc(rem(260px));
$aside-navigation-width--close: calc(rem(64px));
$product-filters-fixed-header-height: calc(rem(110px));

// desktop
$summary-width--open: rem(340px);
$summary-width--close: rem(45px);

// mobile
$summary-height--open: rem(340px);
$summary-height--close: rem(53px);

$summary-header-height: rem(105px);

$mars-footer-height: rem(90px);
$app-footer-height: rem(168px);
$cookies-button-height: rem(38px);

/* Animation Easing ========================================================================== */

$ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
