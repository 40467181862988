@import '~styles/utils/site-variables';

[id='rc-chart-tooltip'] {
  background-color: $white;
  font-family: $din-bold;
  z-index: 2;
}

.rc-chart-tooltip {
  font-size: 16px;
  position: relative;
  text-transform: uppercase;

  &_arrow {
    height: 25px;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 50px;

    &::after {
      background-color: $white;
      border: 1px solid $black;
      border-color: inherit;
      content: '';
      height: 10px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      width: 10px;
    }
  }

  &_circle {
    background-color: $white;
    border: 3px solid $grey;
    border-radius: 10px;
    height: 15px;
    left: 39%;
    overflow: hidden;
    position: absolute;
    top: 48px;
    width: 15px;
  }
}
