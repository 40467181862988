/**
 * Main layout
 *
 * Copyright © 2018 Royal Canin. All rights reserved.
 */
@import '~styles/utils/all';
//@import '~@angular/material/theming';

.l-main {
  display: block;
  margin-top: calc(#{$header-height} + #{$sub-header-height});
  position: relative;
  z-index: 1;

  @media only screen and (min-width: 960px) {
    margin-top: calc(#{$header-height--desktop} + #{$sub-header-height--desktop});
  }

  &__container {
    height: 100%;
    position: relative;
    width: 100%;
  }
}

@media only print {
  .l-main {
    margin: 20px;
  }
}
