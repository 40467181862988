@import '~styles/utils/all';

.rc-overlay-container,
.rc-overlay-wrapper {
  height: 100%;
  left: 0;
  pointer-events: none;
  top: 0;
  width: 100%;
}

.rc-overlay-container {
  position: fixed;
  z-index: 2000;
}

.rc-overlay-backdrop {
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1000;

  &--dark {
    background: rgb(0 0 0 / 32%);
  }

  &--showing {
    opacity: 1;
  }
}

.rc-overlay-wrapper {
  position: absolute;
  z-index: 1000;
}

.rc-overlay-pane {
  max-height: 100%;
  max-width: 100%;
  pointer-events: auto;
  position: static;
  z-index: 1000;
}

.customMatDialogOverlay {
  background: rgba($grey-darker, 0.8) !important;
  opacity: 1 !important;
}

.popinDefaultPanelCustom .mat-dialog-container {
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: 70vh;
    width: rem(800px);
  }
}

.productDetailPanelCustom .mat-dialog-container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(680px);
    width: rem(920px);
  }
}

.shareRecomendationPanelCustom .mat-dialog-container {
  background-color: $white;
  height: 100vh;
  width: 100vw;
  @include from($desktop) {
    height: rem(900px);
    width: rem(1280px);
  }
}

@media only screen and (min-width: 960px) {
  .rc-overlay-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .rc-overlay-pane {
    display: flex;
    // max-width: 80vw;

    &.big {
      width: 1160px;
    }

    &.plus {
      width: 920px;
    }

    &.medium {
      width: 920px;
    }

    &.regular {
      width: 580px;
    }

    &.small {
      width: 440px;
    }
  }
}
